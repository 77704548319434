<template>
  <!-- 上半部 -->
  <div class="row mb-1">
    <!-- 按鈕 -->
    <div class="col-md-5 col-12 mb-1">
      <!-- 批次操作 -->
      <div class="dropdown d-inline-block me-2 mb-1">
        <button
          class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
          id="MerchOrderBatch"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          批次操作
        </button>
        <ul class="dropdown-menu" aria-labelledby="MerchOrderBatch">
          <li>
            <button class="dropdown-item" @click="showModal('soldOut')">
              斷貨
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="showModal('setAllocatedAmount', 'max')"
            >
              全數配單
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="showModal('setAllocatedAmount', 'min')"
            >
              配單歸 0
            </button>
          </li>
          <li>
            <button class="dropdown-item" @click="showModal('del')">
              刪除
            </button>
          </li>
        </ul>
      </div>
      <!-- 執行 -->
      <div class="dropdown d-inline-block me-2 mb-1">
        <button
          class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
          id="MerchOrderAction"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          執行
        </button>
        <ul class="dropdown-menu" aria-labelledby="MerchOrderAction">
          <li>
            <button class="dropdown-item" @click="showModal('exportExcel')">
              匯出
            </button>
          </li>
        </ul>
      </div>
      <!-- 常用搜尋 -->
      <div class="dropdown d-inline-block me-1 mb-1">
        <button
          class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
          id="FrequentlySearched"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          常用搜尋
        </button>
        <ul class="dropdown-menu" aria-labelledby="FrequentlySearched">
          <li>
            <button
              class="dropdown-item"
              @click="frequentlySearched('配單數 = 0')"
            >
              配單數 = 0
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="frequentlySearched('部分配單')"
            >
              部分配單
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="frequentlySearched('完全配單')"
            >
              完全配單
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="frequentlySearched('配單數 > 0')"
            >
              配單數 > 0
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="frequentlySearched('結單數 = 0')"
            >
              結單數 = 0
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="frequentlySearched('部分結單')"
            >
              部分結單
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="frequentlySearched('完全結單')"
            >
              完全結單
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              @click="frequentlySearched('結單數 > 0')"
            >
              結單數 > 0
            </button>
          </li>
        </ul>
      </div>
      <!-- 進階搜尋 -->
      <button
        class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
        @click="showModal('advancedSearch')"
      >
        進階搜尋
      </button>
      <!-- 切換新舊版訂單管理 -->
      <!-- <button
        class="me-1 mb-1 tw-btn tw-btn-hover"
        :class="{
          'tw-btn-danger': newDataTable,
          'tw-btn-success': !newDataTable,
        }"
        @click="switchDataTableVersion"
      >
        <span v-if="newDataTable">切換舊版訂單</span>
        <span v-else>切換新版訂單</span>
      </button> -->
    </div>
    <!-- 訊息 -->
    <div class="col-md-7 col-12 mb-1 fw-bolder">
      <template v-if="recordAdvancedSearchData">
        <p>
          搜尋條件:
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.merchandiseName"
          >
            商品名稱 {{ recordAdvancedSearchData.merchandiseName }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.supplier"
          >
            供應商: {{ recordAdvancedSearchData.supplier.name }} </span
          ><span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.stylesName"
          >
            款式名稱 {{ recordAdvancedSearchData.stylesName }}
          </span>
          <span class="badge bg-primary me-1 mb-1">
            訂單狀態:
            {{ recordAdvancedSearchData.singleSelect.orderStatus }}</span
          >
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.allocatedStatus !== '不選擇'
            "
          >
            配單狀態:
            {{ recordAdvancedSearchData.singleSelect.allocatedStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.checkoutStatus !== '不選擇'
            "
          >
            結單狀態:
            {{ recordAdvancedSearchData.singleSelect.checkoutStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.paymentStatus !== '不選擇'
            "
          >
            收款狀態:
            {{ recordAdvancedSearchData.singleSelect.paymentStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.shippedStatus !== '不選擇'
            "
          >
            寄出狀態:
            {{ recordAdvancedSearchData.singleSelect.shippedStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.soldOutStatus !== '不選擇'
            "
          >
            斷貨狀態:
            {{ recordAdvancedSearchData.singleSelect.soldOutStatus }}
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.timeRange.switch">
            搜尋區間:
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                recordAdvancedSearchData.timeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                !recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.DLtimeRange.switch">
            商品收單區間:
            <span
              v-if="
                recordAdvancedSearchData.DLtimeRange.startTime &&
                recordAdvancedSearchData.DLtimeRange.endTime
              "
              >{{ recordAdvancedSearchData.DLtimeRange.startTime }}~{{
                recordAdvancedSearchData.DLtimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.DLtimeRange.startTime &&
                !recordAdvancedSearchData.DLtimeRange.endTime
              "
              >{{ recordAdvancedSearchData.DLtimeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.DLtimeRange.startTime &&
                recordAdvancedSearchData.DLtimeRange.endTime
              "
              >{{ recordAdvancedSearchData.DLtimeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </template>
      <p
        v-if="
          (currentParticipantPermission &&
            currentParticipantPermission.accessStoreReport) ||
          !currentParticipantPermission
        "
      >
        獲利摘要:
        <span v-if="profit">{{ profit }}</span>
        <span class="text-danger" v-else>計算中...</span>
      </p>
    </div>
  </div>
  <!-- new dataTable -->
  <MerchOrderTable
    v-if="newDataTable"
    :showWhitchModal="showWhitchModal"
    :frequentlySearchedStatus="frequentlySearchedStatus"
    @advancedSearchData="advancedSearchData"
    @profitString="profitString"
  >
  </MerchOrderTable>
  <!-- old dataTable -->
  <OldMerchOrderDataTalbe
    v-else
    :showWhitchModal="showWhitchModal"
    :frequentlySearchedStatus="frequentlySearchedStatus"
    @advancedSearchData="advancedSearchData"
    @profitString="profitString"
  >
  </OldMerchOrderDataTalbe>
</template>

<script>
// components
import MerchOrderTable from '../../../components/merchOrder/MerchOrderTable.vue' // new
import OldMerchOrderDataTalbe from '../../../components/merchOrder/OldMerchOrderTable.vue' // old
// mixin
import { Permission } from '@/methods/mixins/permission';

export default {
  components: {MerchOrderTable, OldMerchOrderDataTalbe},
  data() {
    return {
      // 通知子層打開 modal 用
      showWhitchModal: {
        soldOut: false,
        setAllocatedAmount: false,
        del: false,
        editOrder: false,
        exportExcel: false,
        advancedSearch: false,
      },
      frequentlySearchedStatus: null,
      profit: null,
      recordAdvancedSearchData: null,
      newDataTable: true,
    }
  },
  mixins: [Permission],
  methods: {
    showModal(status, item) {
      if (item) this.showWhitchModal[status] = [true, item]
      else this.showWhitchModal[status] = true
    },
    // 常用搜尋
    frequentlySearched(frequentlySearchedStatus) {
      this.frequentlySearchedStatus = frequentlySearchedStatus
    },
    // 來自<訂單的 dataTable>子層的通知 (進階搜尋)
    advancedSearchData(object) {
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(object))
    },
    // 來自<訂單的 dataTable>子層的通知 (獲利摘要)
    profitString(value) {
      this.profit = value
    },
    // 切換 dataTable 版本
    switchDataTableVersion() {
      this.newDataTable = !this.newDataTable
    },
  }
}
</script>